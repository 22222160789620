import { useRef, useState } from 'react';

import { axiosInstance } from 'api';
import { Slots, InstantGames, Pokers } from 'constants';
import { getError } from 'utils/appHelpers';
import { GameCard } from 'components/GameCard';
import { Header } from 'components/Header';
import { Button } from 'components/Button';
import { Footer } from 'components/Footer';
import { CircularProgress } from 'components/CircularProgress';
import { IframeBlock } from 'components/IframeBlock';
import { Toast } from 'components/Toast';

// Images
import CustomizableIcon from 'assets/icons/customizable_icon.svg';
import TestedIcon from 'assets/icons/tested_icon.svg';
import ProtectedIcon from 'assets/icons/protected_icon.svg';

const emptyForm = {
  contactName: '',
  contactSurname: '',
  contactCompany: '',
  message: '',
  email: '',
  fromPage: 'gambling',
};

export const Home = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState(emptyForm);
  const [currentIframeSrc, setCurrentIframeSrc] = useState('');

  const formRef = useRef(null);

  const handleOpenIframe = (src) => {
    setCurrentIframeSrc(src);
  };

  const handleChange = ({ name, value }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleTextareaKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      event.target.form.querySelector('[type="submit"]').click();
    }
  };

  const scrollToForm = () => formRef.current.scrollIntoView({ behavior: 'smooth' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('contName', form.contactName);
    formData.append('contSurname', form.contactSurname);
    formData.append('contComp', form.contactCompany);
    formData.append('message_a', form.message);
    formData.append('email_a', form.email);
    formData.append('from_page', form.fromPage);
    formData.append('submit', 'submit');

    try {
      const res = await axiosInstance.post('/sendmsg.php', formData);
      if (res?.data?.error) {
        throw new Error(res.data.error);
      }
      Toast({ type: 'success', message: 'Successfully Sent' });
      setForm(emptyForm);
    } catch (err) {
      console.log('err', err);
      Toast({ type: 'error', message: getError(err) });
    }

    setIsSubmitting(false);
  };

  return (
    <>
      {currentIframeSrc && (
        <IframeBlock currentIframeSrc={currentIframeSrc} close={() => setCurrentIframeSrc('')} />
      )}
      <div className={`${currentIframeSrc && 'overflow_hidden'}`}>
        <Header scrollToForm={scrollToForm} />
        <div className='content_container'>
          <div className='games_block'>
            <h2 className='title start'>Instant Games</h2>
            <div className='games_container'>
              {InstantGames.map((item, idx) => (
                <GameCard
                  handleClick={() => handleOpenIframe(item.iframeSrc)}
                  key={idx}
                  img={item.img}
                />
              ))}
            </div>
          </div>
          <div className='games_block'>
            <h2 className='title start'>Slots</h2>
            <div className='games_container'>
              {Slots.map((item, idx) => (
                <GameCard
                  handleClick={() => handleOpenIframe(item.iframeSrc)}
                  key={idx}
                  img={item.img}
                  iframeSrc={item.iframeSrc}
                />
              ))}
            </div>
          </div>
          <div className='games_block'>
            <h2 className='title center'>Video Poker</h2>
            <div className='games_container poker'>
              {Pokers.map((item, idx) => (
                <GameCard
                  handleClick={() => handleOpenIframe(item.iframeSrc)}
                  key={idx}
                  img={item.img}
                />
              ))}
            </div>
          </div>
          <div className='regular_block'>
            <h2 className='title center'>
              We create <span className='blue_text'>winning</span> games for everyone
            </h2>
            <div className='content'>
              <p className='reg_txt'>
                We know how games should be created, no detail is neglected; starting from technical
                aspects up to how to attract users by interface solutions.
              </p>
              <p className='reg_txt'>
                We achieve more efficient usage of server resources, more simultaneous user support
                on one server and much faster user interaction compared to our competitors by
                implementing low level utilization of hardware resources, simultaneous processing of
                complex calculations, user side rendering of graphics and animations.
              </p>
              <p className='reg_txt'>
                Our UI / UX design team makes sure to create attractive interface taking in account
                user habits, good practices, user experience and user test results.
              </p>
              <p className='reg_txt'>
                All our games are successfully integrated to active game providing portals, proved
                themselves well, loved by players and brought revenue to platform owners.
              </p>
              <p className='reg_txt'>
                We are ready to provide any technical or other information about existing games or
                to discuss any new game that you have in your mind.
              </p>
            </div>
          </div>
          <div className='icons_block'>
            <div className='icon_item center'>
              <img width={106} alt='custom' src={ProtectedIcon} />
              <p className='blue_text text_bold'>100%</p>
              <p className='text_bold'>Protected</p>
            </div>
            <div className='icon_item center'>
              <img width={106} alt='custom' src={TestedIcon} />
              <p className='blue_text text_bold'>100%</p>
              <p className='text_bold'>Tested</p>
            </div>
            <div className='icon_item center'>
              <img width={106} alt='custom' src={CustomizableIcon} />
              <p className='blue_text text_bold'>100%</p>
              <p className='text_bold'>Customizable</p>
            </div>
          </div>
          <div className='request_test_block'>
            <h2 className='title'>DO YOU WANT TO TEST?</h2>
            <Button onClick={scrollToForm} className='btn_secondary'>
              Request Test
            </Button>
          </div>
          <form ref={formRef} onSubmit={handleSubmit} className='form_container'>
            <h2 className='title'>Have questions? Contact us</h2>
            <div className='row'>
              <div className='field_block'>
                <label htmlFor='name'>
                  Name<sup className='required'>*</sup>
                </label>
                <input
                  required
                  className='field'
                  id='name'
                  onChange={({ target }) => handleChange(target)}
                  name='contactName'
                  value={form.contactName}
                />
              </div>
              <div className='field_block'>
                <label htmlFor='surname'>
                  Surname<sup className='required'>*</sup>
                </label>
                <input
                  required
                  className='field'
                  id='surname'
                  onChange={({ target }) => handleChange(target)}
                  name='contactSurname'
                  value={form.contactSurname}
                />
              </div>
            </div>
            <div className='row'>
              <div className='field_block'>
                <label htmlFor='email'>
                  Email<sup className='required'>*</sup>
                </label>
                <input
                  required
                  className='field'
                  id='email'
                  onChange={({ target }) => handleChange(target)}
                  name='email'
                  value={form.email}
                  type='email'
                />
              </div>
            </div>
            <div className='row'>
              <div className='field_block'>
                <label htmlFor='company'>Company</label>
                <input
                  className='field'
                  id='company'
                  onChange={({ target }) => handleChange(target)}
                  name='contactCompany'
                  value={form.contactCompany}
                />
              </div>
            </div>
            <div className='row'>
              <div className='field_block'>
                <label htmlFor='description'>
                  Description<sup className='required'>*</sup>
                </label>
                <textarea
                  required
                  className='field'
                  id='description'
                  resize='none'
                  onKeyDown={handleTextareaKeyDown}
                  onChange={({ target }) => handleChange(target)}
                  name='message'
                  value={form.message}
                />
              </div>
            </div>
            <div className='row button_row center'>
              <Button type='submit' className='btn_primary middle'>
                {isSubmitting ? <CircularProgress /> : 'Send'}
              </Button>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    </>
  );
};
