import React from 'react';

export const CircularProgress = ({ size, color }) => {
  const circleSize = size || 30;
  const circleColor = color || '#fff';

  return (
    <div className='circular-progress-container' style={{ width: circleSize, height: circleSize }}>
      <div
        className='circular-progress'
        style={{ borderTopColor: circleColor, borderWidth: '4px' }}
      ></div>
    </div>
  );
};
