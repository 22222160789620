import React from 'react';
import { toast } from 'react-toastify';

export const Toast = ({ type, message }) =>
  toast[type](
    <div style={{ display: 'flex' }}>
      <div
        style={{
          textAlign: 'left',
          flexGrow: 1,
          fontSize: 15,
          padding: '8px 12px',
          display: 'flex',
        }}
      >
        {message}
      </div>
    </div>,
  );

Toast.dismiss = toast.dismiss;
