// Instants

import Card1 from 'assets/images/gameCards/Instants/cardImg1.jpg';
import Card2 from 'assets/images/gameCards/Instants/cardImg2.jpg';
import Card3 from 'assets/images/gameCards/Instants/cardImg3.jpg';
import Card4 from 'assets/images/gameCards/Instants/cardImg4.jpg';
import Card5 from 'assets/images/gameCards/Instants/cardImg5.jpg';
import Card6 from 'assets/images/gameCards/Instants/cardImg6.jpg';
import Card7 from 'assets/images/gameCards/Instants/cardImg7.jpg';

//Slots

import Slot1 from 'assets/images/gameCards/Slots/slotImg1.jpg';
import Slot2 from 'assets/images/gameCards/Slots/slotImg2.jpg';
import Slot3 from 'assets/images/gameCards/Slots/slotImg3.jpg';
import Slot4 from 'assets/images/gameCards/Slots/slotImg4.jpg';
import Slot5 from 'assets/images/gameCards/Slots/slotImg5.jpg';
import Slot6 from 'assets/images/gameCards/Slots/slotImg6.jpg';

// Pokers

import Poker1 from 'assets/images/gameCards/Pokers/poker1.jpg';
import Poker2 from 'assets/images/gameCards/Pokers/poker2.jpg';

export const InstantGames = [
  {
    img: Card1,
    iframeSrc:
      'https://penalty-demo.atlas-v.com/demo/test/3c6a7505540e47ad8b0ae2d3ca3be8c2?pid=demouser&cid=63c949237c5509161c8f9bd4&token=2ae84b7bf1412a44719ec5bd86ba62b853b7fddc&time=1680071091272',
  },
  {
    img: Card2,
    iframeSrc:
      'https://blue-thunder-demo.atlas-v.com/demo/test/1760cd2d55ed4fb180322b402b5a5f82?pid=demouser&cid=63d118cc325184204810500d&token=b29e61b1b23469558f7eaa44365307f92b17d3a4&time=1680071166207',
  },
  {
    img: Card3,
    iframeSrc:
      'https://darts-demo.atlas-v.com/demo/test/2c7fc5887d45423ca3603dd19f3e0a4c?pid=demouser&cid=63ca842b1158351344a8e1a4&token=951cdcb11153eb544c01a4cf674350ec4cdb0cdb&time=1680071144215',
  },
  {
    img: Card4,
    iframeSrc:
      'https://wowbow-demo.atlas-v.com/demo/test/21621886d18741ae9799639984f90336?pid=demouser&cid=63c7cf9ac8d399412020c182&token=59332e8b48a043874e61f1e8d0a4b3fc4b1fbbf3&time=1680071026261',
  },
  {
    img: Card5,
    iframeSrc:
      'https://formula-demo.atlas-v.com/demo/test/1dceb87d747b416bbfd4f88a94ad3a85?pid=demouser&cid=63d1236f39dd4743c06f95bd&token=37e83947b1efc2f10aa7592e5fa5505eb7fa7fa9&time=1680071121202',
  },
  {
    img: Card6,
    iframeSrc:
      'https://winball-demo.atlas-v.com/demo/test/4aac2a20a16947d7a22a30f357fcf518?pid=demouser&cid=63c90aba0f65434b1c316dff&token=dbd47a691480bfa2810f5b85b893bd9ec6c8f0fe&time=1680071069206',
  },
  {
    img: Card7,
    iframeSrc:
      'https://boomball-nrg1.atlas-v.com/demo/6423c96a2a5f638c323b0429/b35f2dfc57a74ecebadbae4d844d6716',
  },
];

export const Slots = [
  {
    img: Slot1,
    iframeSrc: 'https://slots.atlas-v.com/demo/tavern/6423d4a824954d52a0395f61/testuser',
  },
  {
    img: Slot2,
    iframeSrc: 'https://slots.atlas-v.com/demo/juicyfruits/6423d4a824954d52a0395f61/testuser',
  },
  {
    img: Slot3,
    iframeSrc: 'https://slots.atlas-v.com/demo/fairyland/6423d4a824954d52a0395f61/testuser',
  },
  {
    img: Slot4,
    iframeSrc: 'https://slots.atlas-v.com/demo/chukchaman/6423d4a824954d52a0395f61/testuser',
  },
  {
    img: Slot5,
    iframeSrc: 'https://slots.atlas-v.com/demo/monkeyboy/6423d4a824954d52a0395f61/testuser',
  },
  {
    img: Slot6,
    iframeSrc: 'https://slots.atlas-v.com/demo/headsortails/6423d4a824954d52a0395f61/testuser',
  },
];

export const Pokers = [
  {
    img: Poker1,
    iframeSrc: 'https://slots.atlas-v.com/demo/poker/6423d4a824954d52a0395f61/testuser',
  },
  {
    img: Poker2,
    iframeSrc: 'https://slots.atlas-v.com/demo/jokerpoker/6423d4a824954d52a0395f61/testuser',
  },
];
