import LogoRed from 'assets/icons/logo_red.svg';

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className='footer'>
      <img src={LogoRed} alt='Accontech' />
      <p className='content_text'>
        Powered by{' '}
        <a rel='noreferrer' href='https://accontech.com/' target='_blank'>
          Accontech
        </a>
      </p>
      <p className='rights_reserved'>Accontech {currentYear} ! All rights reserved</p>
    </div>
  );
};
