import { Button } from '../Button';
import Logo from 'assets/icons/logo.svg';

export const Header = ({ scrollToForm }) => {
  return (
    <div className='header_container'>
      <div className='top_section'>
        <img width={297} alt='logo' src={Logo} />
        <Button onClick={scrollToForm} className='btn_primary big'>
          Become Partner
        </Button>
      </div>
      <div className='body_section'>
        <h1 className='title'>Games developed by game developers</h1>
        <div className='content'>
          <p>
            We create games ensuring all details for the success: thrilling plots, exciting and
            empirical user interface, and reliable software.
          </p>
          <p>
            Our goal is your business growth via our cutting-edge and state-of-the-art technology.
          </p>
        </div>
      </div>
    </div>
  );
};
