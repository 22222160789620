import CloseIcon from 'assets/icons/close_menu.svg';

export const IframeBlock = ({ currentIframeSrc, close }) => (
  <div className='iframe_container'>
    <div className='iframe_block'>
      <iframe
        width='100%'
        height='100%'
        title='current game'
        src={currentIframeSrc}
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        sandbox='allow-scripts allow-forms allow-popups allow-same-origin'
      ></iframe>
    </div>
    <div onClick={close} className='close_block'>
      <img className='close' alt='close' src={CloseIcon} />
    </div>
  </div>
);
